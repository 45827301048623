import React, { useState, useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';

import './App.css';

// Hides the body initially until we know whether to redirect or not
const hideBodyStyle = {
  display: 'none',
};

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [isLoading, setIsLoading] = useState(true); // New loading state

  useEffect(() => {
    setIsLoading(true); // Start loading before the fetch

    fetch('/api/location')
      .then((response) => response.json())
      .then((data) => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));

        if (decodedCity === "unknown") {
          fetch('https://freeipapi.com/api/json/')
            .then((response) => response.json())
            .then((externalData) => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);

              if (externalData.countryCode === 'US') {
                // Use the regionName (state) instead of the city
                setCity(externalData.regionName); // Clear city for US
              } else {
                setCity(externalDecodedCity);
                setRegion(regionNames.of(externalData.countryCode.toUpperCase()));
              }

              if (externalData.countryCode === 'SE') {
                window.location.href = 'https://youtube.com'; // Redirect if Swedish
              } else {
                document.body.style.display = 'block'; // Show body after fetch completes
                setIsLoading(false); // Stop loading if not Swedish
              }
            })
            .catch((error) => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          setCountry(data.country);

          if (data.country === 'US') {
            setCity(data.region); // Clear city for US
          } else {
            setRegion(regionNames.of(data.country.toUpperCase()));
            setCity(decodedCity);
          }

          if (data.country === 'SE') {
            window.location.href = 'https://youtube.com'; // Redirect if Swedish
          } else {
            document.body.style.display = 'block'; // Show body after fetch completes
            setIsLoading(false); // Stop loading if not Swedish
          }
        }
      })
      .catch((error) => {
        console.error('Fetch error on local API:', error);
      });
  }, []);

  return (
    <div className="container" style={isLoading ? hideBodyStyle : {}}>
      <a className="header-logo" href="https://Onlyfans.com/Silva.04">
        <div className="onlyfans"></div>
      </a>
      <img
        src="https://i.ibb.co/Qj8kDHz/photo-2024-07-28-17-56-22-modified.png"
        alt="Header Image"
        className="header-image"
      />

      <div className="textContainer">
        <section>
          <div className="username">
            <strong>Silvie 🤍</strong>
          </div>
          <div className="useraccountname">@silva.04</div>

          <div id="user-status" className="user-status">
            <div className="status"></div>
            <div id="available">Available now</div>
            <div className="textContainer">
              <div className="location"></div>
              <span id="location-name">
                <strong>{city ? `${city}, ${region}` : region ? `${region}` : '<3'}</strong>
              </span>
            </div>
          </div>
        </section>
      </div>

      <div className="textContainer2">
        <div className="time-in-current-location">
          Connecting with local, serious people in {city || region || 'your location'} on OnlyFans. Please be respectful or you'll be blocked ❣️
        </div>
      </div>

      <a href="https://ofmsites.pro/Silva.04" id="customButton">
        <div className="onlyfans"></div>
        Send me a message💕
      </a>

      <Analytics />
    </div>
  );
}

export default App;
